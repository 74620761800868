import { setLocale, addMethod, string, ObjectSchema } from "yup";
import * as Yup from 'yup';

// settings e config
// temos duas fontes de configurações da app, as configs devem ser vistas como
// configurações estáticas, que no máximo são definidas via env vars
// as settings devem ser providenciadas via API


export const REQUIRED_FIELD = 'Campo obrigatório.'
export const EXPIRATION_REQUIRED = "Obrigatório indicar data expiração"

function getEnv(envname: string) {
    // @ts-ignore
    return window._env_ ? window._env_[envname] : ''
}

export const AppErrors = {
    UNDEFINED_CONCESSION: "Concessão Indefinida",
    UNKNOWN_ERROR: "Erro Desconhecido",
    NO_DUPLICATE_ROLE: "Não é possível adicionar a mesma função múltiplas vezes"
}

export const ApiErrorMap: Record<string, string> = {
    "ED0001": "Erro! Não foram encontrados documentos para importação",
    "ED0002": "Erro! Não foi encontrada acreditação de destino",
    "EV0001": "Erro! Documentos não podem expirar antes da acreditação",
    "ES0001": "Erro! Não foi possível gerar PDF, por favor contacte administrador"
}


const Config = {
    GRAPHQL_API: getEnv("GRAPHQL_API"),
    MEDIA_URL: getEnv("MEDIA_URL"),
    AUTH_ENDPOINT: getEnv("AUTH_ENDPOINT"),
    JWT_AUTH_HEADER_PREFIX: "Bearer",
    AUTH_TOKEN_KEY: "auth.token",
    START_ROUTE: "/",
    PROTECT_DEFAULT_REDIRECT_ROUTE: "/login",
    PAGE_SIZE: 25,
    // FORMAT DATE
    // DEFAULT_FORMAT_DATE: "dd'-'MM'-'yyyy",
    DEFAULT_FORMAT_DATETIME: "dd'/'MM'/'yyyy HH:mm",
    DATE_FORMAT: "dd'/'MM'/'yyyy",
    DATETIME_FORMAT: "dd'/'MM'/'yyyy HH:mm",
    DATE_INPUT_FORMAT: "dd/MM/yyyy",
    DEFAULT_FADE_TIMEOUT: 300,
    NO_OPTIONS_TEXT: "Sem Opções",
    NO_RESULTS_TEXT: "Sem Resultados",
    APP_ERRORS: AppErrors,
    MAX_FILE_SIZE: 300000, // em bytes
    // NEW AND NORMALIZED DATE AND TIME FORMATS E#824
    // DATAS SÂO USADAS INTERNAMENTE (APP) E EXTERNAMENTE (API) (Excel via API)
    // INTERNAMENTE SÂO USADAS EM INPUTS, LISTAS, QUERY STRING
    GIN_DATE_FORMAT: "dd/MM/yyyy", // input format para desktop date picker
    GIN_DATETIME_FORMAT: "dd/MM/yyyy HH:mm",
    GIN_API_DATE_FORMAT: 'yyyy-MM-dd', // formato aceite pela API, só usado no último momento
    GIN_ISO_DATE_FORMAT: 'yyyy-MM-dd',
    APP_URL: getEnv("APP_URL"),
    DEFAULT_FIELD_QUERY_PAGING: { limit: 9999 },
    MAX_UPLOAD_SIZE: getEnv("MAX_UPLOAD_SIZE"),
    ALLOWED_PHOTO_FORMATS: getEnv("ALLOWED_PHOTO_FORMATS"),
    MUI_LICENSE_KEY: getEnv("MUI_LICENSE_KEY"),
    PAGE_SIZE_OPTIONS: [10, 25, 50],
    MAX_RESULTS_SIZE: 9999,
    GOOGLE_MAPS_API_KEY: getEnv("GOOGLE_MAPS_API_KEY"),
    SUBCONTRACT_ORGANIZATION_TYPE: "SUBCONTRACT"
};

setLocale({
    mixed: {
        default: 'Não é válido',
        required: REQUIRED_FIELD
    },
    number: {
        // no-template-curly-in-string
        // as variáveis não são definidas
        // neste contexto, pelo que não se podemos
        // usar template strings
        // eslint-disable-next-line
        min: 'Tem que ser maior que ${min}',
        // eslint-disable-next-line
        max: 'Tem que ser menor que ${max}',
        integer: 'Campo tem que ser um número inteiro',
        positive: 'Tem que ser um número positivo'
    },
    string: {
        length: 'Tem que ter 8 digitos.',
        // eslint-disable-next-line
        min: 'Tem que ser maior que ${min}',
        // eslint-disable-next-line
        max: 'No máximo ${max} caracteres.',
        email: "Tem que ser um email válido"
    },
    // date: {
    //     format: "Formato Inválido"
    // }
});


// yup custom schemas

export const isValidPK = (pk: any) => {
    const re = /[0-9]+\+[0-9]+/gm
    return re.test(pk)
}

addMethod(string, 'pk', function() {
    return this.test('pk', "Valor inválido, usar formato (0+000).", isValidPK)
})

export function requiredDate() {
    return Yup.date().required().default(null).typeError(REQUIRED_FIELD)
}

export function requiredEndDate(startField: string, message: string = "data final tem de ser posterior à data inicial") {
    return requiredDate().test(
        "is-greater",
        message,
        function(value) {
            const referenceDate = this.parent[startField];
            return value > referenceDate;
        }
    )
}

// https://github.com/jaredpalmer/formik/discussions/3026
// Initial Valies From Schema
export const getInitialValues = (schema: ObjectSchema<any>) => {
    return schema.cast({});
}


export const acceptedFileTypes = {
    'application/pdf': ['pdf'],
    'image/jpeg': ['jpeg', 'jpg'],
    'image/png': ['png'],
    'image/gif': ['gif'],
}


// state colors
export const gray = "#6D6D6D" // cinza
export const yellow = "#FDAA3E" // amarelo
export const green = "#00C072" // verde
export const red = "#E3435D" // vermelho
export const blue = "#599bfd" // azul
export const black = "#000000" // preto

export default Config;
