import { Card, CardContent, Grid, Typography } from "@mui/material"
import { FC } from "react"
import { SurveyRecordVerification as SurveyRecordVerificationType } from "../../generated/graphql"
import { SurveyRecordVerificationsProps } from "../../types/types"
import CreateSurveyRecordVerification from "../CreateSurveyRecordVerification/CreateSurveyRecordVerification"
import Empty from "../Empty/Empty"
import Show from "../Show/Show"
import SurveyRecordVerification from "../SurveyRecordVerification/SurveyRecordVerification"
import styles from './SurveyRecordVerifications.module.scss'

const SurveyRecordVerifications: FC<SurveyRecordVerificationsProps> = props => {
    const { surveyRecord, refetch, allowed, isNotUserEdit } = props

    return (
        <Grid container className={styles.SurveyRecordVerifications}>
            <Card style={{ width: "100%", padding: 20 }}>
                <CardContent>
                    <Typography variant="h4" color="primary">Verificações</Typography>
                    <div>
                        {surveyRecord?.surveyRecordVerifications?.map(
                            (item: SurveyRecordVerificationType) => <SurveyRecordVerification
                                key={item?.id} surveyRecordVerification={item} refetch={refetch} isNotUserEdit={isNotUserEdit} allowed={allowed} />)}
                        <Show on={!surveyRecord?.surveyRecordVerifications?.length}>
                            <Empty message="Ainda não existem verificações." variant="subtitle1" />
                        </Show>
                        <div style={{ marginTop: 50 }}></div>

                        <Show on={allowed}>
                            <CreateSurveyRecordVerification refetch={refetch} surveyRecord={surveyRecord} isNotUserEdit={isNotUserEdit} />
                        </Show>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default SurveyRecordVerifications
