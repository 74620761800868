import { getContractExecutingOrganization } from "../../ContractConcessionOrganizationAutocomplete/ContractConcessionOrganizationAutocomplete";

const getContractConcessionOrganizations = (params: any) => {
    if (params?.row?.__typename === "Contract") {
        return params?.row?.contractConcessionOrganizations
    } else if (params?.row?.__typename === "Task") {
        return params?.row?.intervention?.workPermit?.contract?.contractConcessionOrganizations
    }
}

export const ExecutingOrganizationRender = (params: any) => {
    const contractConcessionOrganizations = getContractConcessionOrganizations(params)
    const executingOrganization = getContractExecutingOrganization(contractConcessionOrganizations)
    const { organization } = executingOrganization?.concessionOrganization!
    return <span>{organization?.name}</span>
}
