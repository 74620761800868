import { Card, CardContent, Fade, Grid, Typography } from "@mui/material"
import { Form, Formik, FormikValues } from "formik"
import { useState } from "react"
import { ConcessionUser, Contract, useContractQuery, useCreateManyContractConcessionOrganizationsDedupedMutation, useCreateOneContractMutation, useSetContractRoadsForContractMutation, useSetContractUsersForContractMutation, useUpdateOneContractMutation } from '../../generated/graphql'
import useFormFieldManager from "../../hooks/useFormFieldManager"
import useGoTo from "../../hooks/useGoto"
import { useHasOneOfRoles } from "../../hooks/useHasRole"
import usePermission from "../../hooks/usePermission"
import useToast from "../../hooks/useToast"
import { Actions, Subjects } from "../../services/ability"
import Config from "../../services/config"
import { createTransform, omit, updateTransform } from "../../services/utils"
import ActionsBar from "../ActionsBar/ActionsBar"
import BackButton from "../BackButton/BackButton"
import contractFormInit from "../ContractForm/ContractFormInit"
import ContractingOrganizationField from "../ContractingOrganizationField/ContractingOrganizationField"
import ContractManagerField from "../ContractManagerField/ContractManagerField"
import ContractRoadsField from "../ContractRoadsField/ContractRoadsField"
import ContractTimeline from "../ContractTimeline/ContractTimeline"
import ContractTypeField from "../ContractTypeField/ContractTypeField"
import { contractFieldRules } from "../ContractView/contractFieldRules"
import ContractSubviewSwitcher from "../ContractView/ContractSubviewSwitcher"
import ContractViewMoreActions from "../ContractView/ContractViewMoreActions"
import CustomModal from "../CustomModal/CustomModal"
import DisplayState from "../DisplayState/DisplayState"
import ExecutingOrganizationField from "../ExecutingOrganizationField/ExecutingOrganizationField"
import ExternalAdministrativeField from "../ExternalAdministrative/ExternalAdministrative"
import ExternalAuthorityField from "../ExternalAuthorityField/ExternalAuthorityField"
import ExternalManagerField from "../ExternalManagerField/ExternalManagerField"
import ExternalTechnicianField from "../ExternalTechnicianField/ExternalTechnicianField"
import InputDateField from "../InputDateField/InputDateField"
import InputNumberField from "../InputNumberField/InputNumberField"
import InputTextField from "../InputTextField/InputTextField"
import InspectingOrganizationField from "../InspectingOrganizationField/InspectingOrganizationField"
import InterventionElementField from "../InterventionElementField/InterventionElementField"
import InterventionLocationField from "../InterventionLocationField/InterventionLocationField"
import InterventionTypeField from "../InterventionTypeField/InterventionTypeField"
import MessageBoard from "../MessageBoard/MessageBoard"
import Permission from "../Permission"
import ProjectingOrganizationField from "../ProjectingOrganizationField/ProjectingOrganizationField"
import RecordNotFound from "../RecordNotFound/RecordNotFound"
import SafetyTechnicianField from "../SafetyTechnicianField/SafetyTechnicianField"
import SaveIconButton from "../SaveIconButton/SaveIconButton"
import ServiceField from "../ServiceField/ServiceField"
import ServiceManagerField from "../ServiceManagerField/ServiceManagerField"
import Show from "../Show/Show"
import Truncate from "../Truncate/Truncate"
import styles from './ContractDetails.module.scss'

export const flatContractRoads = (contractRoads: any) => {
    return contractRoads.map((contractRoad: any) => {
        if (contractRoad?.road) {
            return contractRoad?.road?.id
        }
        return contractRoad.id
    })
}

const ContractDetails = (props: any) => {
    const { id, subview, setSubview } = props

    const { data, loading, error, refetch } = useContractQuery({ variables: { id }, fetchPolicy: "network-only" })
    const isFieldDisabled = useFormFieldManager(contractFieldRules)
    const { schema, initial } = contractFormInit(data?.contract as Contract)

    // hack, para corrigir valor de initial value que passa a null e faz a label sobrepor o texto
    initial.originalValue = initial.originalValue ? initial.originalValue : 0
    initial.amendedValue = initial.amendedValue ? initial.amendedValue : 0
    initial.billedValue = initial.billedValue ? initial.billedValue : 0

    const action = id ? Actions.UPDATE : Actions.CREATE
    const allowed = usePermission(action, Subjects.CONTRACT)
    const hasOneOfRoles = useHasOneOfRoles()
    const contractValuesAllowed = hasOneOfRoles(["CONTRACTMANAGER", "SERVICEMANAGER"])

    const { goToContract } = useGoTo()
    const { errorToast, successToast } = useToast()
    const [createMutation] = useCreateOneContractMutation()
    const [updateMutation] = useUpdateOneContractMutation()
    const [setContractRoadsForContractMutation] = useSetContractRoadsForContractMutation()
    const [setContractUsersForContractMutation] = useSetContractUsersForContractMutation()
    const [createManyContractConcessionOrganizationsDeduped] = useCreateManyContractConcessionOrganizationsDedupedMutation()

    const [openTimeline, setOpenTimeline] = useState<boolean>(false)


    // contractRelations
    const contractRelations = ["contractRoads", "contractUsers", "contractConcessionOrganizations"]
    const nonUpdatableFields = ["contractState", "name", "createdAt", "concession", "contractOpenedNotificationSent", "contractClosedNotificationSent", "contractDocuments", "contractTransitions"]

    /**
     *
     */
    const getContractUsersPayload = (values: FormikValues, contract: string) => {

        // temos que excluir os elementos null para não enviar um payload inválido
        const contractUsers = values?.contractUsers.filter((concessionUser: ConcessionUser) => concessionUser)

        // @todo - normalizar payload
        const payload = { id: contract }
        // @ts-ignore
        payload.contractUserRoles = contractUsers?.map((concessionUser: ConcessionUser) => {
            return (
                { role: concessionUser.role, user: concessionUser.user.id }
            )
        })
        return payload
    }

    /**
     *
     */
    const getContractRoadsPayload = (values: FormikValues, contractId: string) => {
        const relatedIds = flatContractRoads(values.contractRoads)
        return { variables: { input: { id: contractId, relatedIds } } }
    }

    /**
     *
     */
    const setContractUsers = async (values: FormikValues, contractId: string) => {
        const input = getContractUsersPayload(values, contractId)
        // @ts-ignore
        return await setContractUsersForContractMutation({ variables: { input } })
    }

    /**
     *
     */
    const setContractConcessionOrganizations = async (values: FormikValues, contractId: string) => {

        // temos que filtrar a array ou vamos criar um payload inválido
        const contractConcessionOrganizations = values?.contractConcessionOrganizations?.filter((obj: any) => obj)

        // primeiro é o update value o segundo é o cretate value
        const concessionOrganizations = contractConcessionOrganizations.map((obj: any) => obj?.concessionOrganization?.id || obj?.id)
        return await createManyContractConcessionOrganizationsDeduped({ variables: { input: { contract: contractId, concessionOrganizations } } })
    }

    /**
     *
     */
    const setContractRoads = async (values: FormikValues, contractId: string) => {
        const payload = getContractRoadsPayload(values, contractId)
        // @ts-ignore
        return await setContractRoadsForContractMutation(payload)
    }

    /**
     * como retornar errors daqui?
     * para já se houver errors returns false caso
     */
    const setRelations = async (values: FormikValues, contractId: string) => {
        setContractUsers(values, contractId)
        setContractRoads(values, contractId)
        setContractConcessionOrganizations(values, contractId)
    }

    const create = async (values: FormikValues) => {
        const contractValues = omit(values, contractRelations)

        try {
            // @ts-ignore
            const response = await createMutation(createTransform({ ...contractValues }, "contract"))
            const contract = response?.data?.createOneContract?.id

            if (contract) {
                await setRelations(values, contract)
                successToast("Contrato Criado")
                goToContract(contract)
            } else {
                errorToast("error ao criar contrato")
            }


        } catch (e: unknown) {
            errorToast("Erro desconhecido.")
        }

    }


    const update = async (values: FormikValues) => {
        const contractValues = omit(values, contractRelations)
        const response = await updateMutation(updateTransform(contractValues, nonUpdatableFields))

        if (response?.errors?.length) {
            errorToast("Erro ao atualizar contrato.")
        } else {
            successToast("Contrato Atualizado")
        }

        if (data?.contract?.id) {
            await setRelations(values, data?.contract?.id)
        }
    }


    const handleSubmit = async (values: FormikValues) => {
        // hack, sem isto, se o utilizador limpar o campo dá erro ao gravar
        values.originalValue = values.originalValue ? values.originalValue : 0
        values.amendedValue = values.amendedValue ? values.amendedValue : 0
        values.billedValue = values.billedValue ? values.billedValue : 0
        data?.contract?.id ? update(values) : create(values)
    }

    const getContractName = () => {
        return data?.contract?.id ? `Contrato ${data?.contract?.name}` : "Novo Contrato"
    }

    const contractState = data?.contract?.contractState?.id
    const showMessageBoard = contractState && +contractState > 2
    const contractUsersDisabled = isFieldDisabled({ fieldName: "contractUsers", state: contractState, action })
    const contractConcessionOrganizationsDisabled = isFieldDisabled({ fieldName: "contractConcessionOrganizations", state: contractState, action })


    if (id && !loading && !data?.contract) {
        return <RecordNotFound />
    }

    return (
        <Formik initialValues={initial} onSubmit={handleSubmit} validationSchema={schema} enableReinitialize validateOnChange={false} validateOnBlur={false} validateOnMount={false}>
            {() => {
                return (
                    <Form>
                        <Fade in={!loading} timeout={Config.DEFAULT_FADE_TIMEOUT}>
                            <div className={styles.Wrapper}>

                                <ActionsBar variant="DETAIL">
                                    <Grid container justifyContent="space-between" style={{ height: "100%" }}>
                                        <Grid item xs={2} container justifyContent="flex-start" alignContent="center">
                                            <BackButton />
                                        </Grid>
                                        <Grid item xs={10} container justifyContent="flex-end" alignContent="center" className={styles.Actions}>
                                            <Permission action={Actions.UPDATE} subject={Subjects.CONTRACT}>
                                                <>
                                                    <SaveIconButton />
                                                    <ContractViewMoreActions contract={data?.contract} refetch={refetch} />
                                                </>
                                            </Permission>
                                        </Grid>
                                    </Grid>
                                </ActionsBar>

                                <Show on={showMessageBoard || false}>
                                    <MessageBoard content="Contrato não se encontra em curso. Contrato não é editável." />
                                </Show>

                                <Grid container justifyContent="space-between" className={styles.Content} component="main" spacing={4}>
                                    <ContractSubviewSwitcher subview={subview} setSubview={setSubview} disabled={!data?.contract?.id} />
                                    <Grid item xs={5} className={styles.Section} component="section">
                                        <Card style={{ padding: 20 }}>
                                            <CardContent>
                                                <Grid container>
                                                    <Grid item xs={9}>
                                                        <Typography variant="h4" color="primary">
                                                            <Truncate text={getContractName()} />
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <DisplayState stateName={data?.contract?.contractState?.name} />
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={6}>
                                                        <div>
                                                            <ContractTypeField
                                                                disabled={!allowed || isFieldDisabled(
                                                                    {
                                                                        fieldName: "contractType",
                                                                        state: contractState,
                                                                        action
                                                                    })}
                                                            />
                                                        </div>

                                                        <div>
                                                            <InterventionElementField
                                                                disabled={!allowed || isFieldDisabled(
                                                                    {
                                                                        fieldName: "interventionElement",
                                                                        state: contractState,
                                                                        action
                                                                    })}
                                                            />
                                                        </div>


                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <div>
                                                            <InterventionTypeField
                                                                disabled={
                                                                    !allowed || isFieldDisabled({
                                                                        fieldName: "interventionType",
                                                                        state: contractState,
                                                                        action
                                                                    })}
                                                            />
                                                        </div>
                                                        <div>
                                                            <InputTextField
                                                                name="description"
                                                                label="descrição"
                                                                multiline={true}
                                                                fullWidth
                                                                disabled={
                                                                    !allowed || isFieldDisabled({
                                                                        fieldName: "description",
                                                                        state: contractState,
                                                                        action
                                                                    })}


                                                            />
                                                        </div>
                                                    </Grid>

                                                </Grid>



                                                <Grid container spacing={4} style={{ marginTop: 20 }}>

                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" color="primary" style={{ marginBottom: 25 }}>
                                                            Localização
                                                        </Typography>
                                                        <Grid container direction="column">
                                                            <div>
                                                                <InterventionLocationField
                                                                    disabled={
                                                                        !allowed || isFieldDisabled({
                                                                            fieldName: "interventionLocation",
                                                                            state: contractState,
                                                                            action
                                                                        })}
                                                                />
                                                            </div>

                                                            <div>
                                                                <ContractRoadsField
                                                                    disabled={
                                                                        !allowed || isFieldDisabled({
                                                                            fieldName: "contractRoads",
                                                                            state: contractState,
                                                                            action
                                                                        })}
                                                                />
                                                            </div>


                                                            <div className={styles.ContractRoadsObservations}>
                                                                <InputTextField
                                                                    name="contractRoadsObservations"
                                                                    label="Sublanços e PKs" multiline
                                                                    fullWidth
                                                                    rows={4}
                                                                    disabled={!allowed || isFieldDisabled({
                                                                        fieldName: "contractRoads",
                                                                        state: contractState,
                                                                        action
                                                                    })}
                                                                />
                                                            </div>
                                                        </Grid>

                                                    </Grid>

                                                    <Grid item xs={6}>
                                                        <Typography variant="h6" color="primary" style={{ marginBottom: 25 }}>
                                                            Entidades
                                                        </Typography>

                                                        <Grid container direction="column">
                                                            <div>
                                                                <ContractingOrganizationField disabled={!allowed || contractConcessionOrganizationsDisabled} />
                                                            </div>
                                                            <div>
                                                                <ProjectingOrganizationField disabled={!allowed || contractConcessionOrganizationsDisabled} />
                                                            </div>
                                                            <div>
                                                                <InspectingOrganizationField disabled={!allowed || contractConcessionOrganizationsDisabled} />
                                                            </div>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>




                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    <Grid item xs={4} className={styles.Section} component="section">
                                        <Card style={{ padding: 20 }}>
                                            <CardContent>
                                                <Typography variant="h4" color="primary">Equipa Interna</Typography>

                                                <div style={{ maxWidth: 300 }}>
                                                    <ServiceField disabled={!allowed || isFieldDisabled({ fieldName: "service", state: contractState, action })} />
                                                </div>

                                                <div>
                                                    <ContractManagerField disabled={!allowed || contractUsersDisabled} />
                                                </div>

                                                <div>
                                                    <ServiceManagerField disabled={!allowed || contractUsersDisabled} />
                                                </div>


                                                <div>
                                                    <SafetyTechnicianField disabled={!allowed || contractUsersDisabled} />
                                                </div>

                                            </CardContent>
                                        </Card>
                                        <Card style={{ padding: 20 }}>
                                            <CardContent>
                                                <Typography variant="h4" color="primary">
                                                    Equipa Externa
                                                </Typography>

                                                <div>
                                                    <ExecutingOrganizationField disabled={!allowed || contractConcessionOrganizationsDisabled} />
                                                </div>

                                                <div>
                                                    <ExternalAuthorityField disabled={!allowed || contractUsersDisabled} />
                                                </div>

                                                <div>
                                                    <ExternalManagerField disabled={!allowed || contractUsersDisabled} />
                                                </div>
                                                <div>
                                                    <ExternalTechnicianField disabled={!allowed || contractUsersDisabled} />
                                                </div>
                                                <div>
                                                    <ExternalAdministrativeField disabled={!allowed || contractUsersDisabled} />
                                                </div>
                                            </CardContent>
                                        </Card>

                                    </Grid>

                                    <Grid item xs={3} className={styles.Section} component="section">

                                        <Grid item className={styles.LocationSection}>
                                            <Card style={{ padding: 20 }}>
                                                <CardContent>
                                                    <Typography variant="h4" color="primary">Cronograma</Typography>

                                                    <Typography variant="h6" color="secondary" gutterBottom>
                                                        Datas Contrato
                                                    </Typography>

                                                    <div>
                                                        <InputDateField
                                                            name="startDate"
                                                            label="Início Contratualizado"
                                                            disabled={!allowed || isFieldDisabled({
                                                                fieldName: "startDate",
                                                                state: contractState,
                                                                action
                                                            })}
                                                        />
                                                    </div>
                                                    <div>
                                                        <InputDateField
                                                            name="endDate"
                                                            label="Fim Contratualizado"
                                                            disabled={!allowed || isFieldDisabled({
                                                                fieldName: "endDate",
                                                                state: contractState,
                                                                action
                                                            })}

                                                        />
                                                    </div>

                                                    <Typography variant="h6" color="secondary" gutterBottom>
                                                        Datas Execução
                                                    </Typography>

                                                    <div>
                                                        <InputDateField
                                                            name="realStartDate"
                                                            label="Início Real"
                                                            disabled={!allowed || isFieldDisabled({
                                                                fieldName: "realStartDate",
                                                                state: contractState,
                                                                action
                                                            })}
                                                        />
                                                    </div>
                                                    <div>
                                                        <InputDateField
                                                            name="realEndDate"
                                                            label="Fim Real"
                                                            disabled={!allowed || isFieldDisabled({
                                                                fieldName: "realEndDate",
                                                                state: contractState,
                                                                action
                                                            })}
                                                        />
                                                    </div>

                                                    <Grid container justifyContent="flex-end">
                                                        <Typography variant="body2" color="primary" onClick={() => setOpenTimeline(true)} style={{ cursor: "pointer" }}>
                                                            {data?.contract?.id && <span>Abrir Timeline</span>}
                                                        </Typography>
                                                    </Grid>
                                                    <CustomModal isActive={openTimeline} handleClose={() => setOpenTimeline(false)}>
                                                        <div>
                                                            <ContractTimeline contract={data?.contract} />
                                                        </div>
                                                    </CustomModal>
                                                </CardContent>
                                            </Card>
                                            <Show on={contractValuesAllowed}>
                                                <Card style={{ padding: 20 }}>
                                                    <CardContent>
                                                        <Typography variant="h4" color="primary">Valores</Typography>

                                                        <div>
                                                            <InputNumberField
                                                                name="originalValue"
                                                                label="Valor Original"
                                                                fullWidth
                                                                disabled={!allowed || isFieldDisabled({
                                                                    fieldName: "originalValue",
                                                                    state: contractState,
                                                                    action
                                                                })} />
                                                        </div>
                                                        <div>
                                                            <InputNumberField
                                                                name="amendedValue"
                                                                label="Valor com Aditamentos"
                                                                fullWidth
                                                                disabled={!allowed || isFieldDisabled({
                                                                    fieldName: "amendedValue",
                                                                    state: contractState,
                                                                    action
                                                                })} />
                                                        </div>
                                                        <div>
                                                            <InputNumberField
                                                                name="billedValue"
                                                                label="Valor Faturado"
                                                                fullWidth
                                                                disabled={!allowed || isFieldDisabled({
                                                                    fieldName: "billedValue",
                                                                    state: contractState,
                                                                    action
                                                                })} />
                                                        </div>
                                                    </CardContent>
                                                </Card>
                                            </Show>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div >
                        </Fade>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default ContractDetails
